import { createStore } from 'vuex'
import userModule from "./modules/userModule"
export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userModule
  }
})
