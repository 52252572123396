let userModule: Object = {
  state: {
    user: false,
    token: false
  },
  mutations: {

  },
  actions: {
    saveInfo(state: any, data: any) {
      state.user = JSON.parse(JSON.stringify(data))
      state.token = data.token
      localStorage.setItem("user", JSON.stringify(state.user))
      localStorage.setItem("token", state.token)

    }
  },
  getters: {

  }
}

export default userModule